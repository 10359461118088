<template>
  <div 
    style="height: 200px; width: 100%; z-index: 2;">
    <highcharts :options="marketShare"></highcharts>
    <span ref="marketProspectusAmount" class="units-title text-800">{{ prospectus | numeralFormat('(0a)') }}</span>
    <span ref="merketProspectusLabel" class="prostects-label text-left font-weight-light">клиентов</span>
    <small ref="marketTotal" class="market-totla-label fs-0 ">Объем рынка: <strong class="text-1000">₽ {{ marketSize | numeralFormat('(0a)') }}</strong></small>
  </div>
</template>

<script>

export default {
  props: ['chartData', 'bgColor', 'prospectus', 'marketSize', 'readonly', 'defaultMax'],
  data: () => ({
  }),

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    this.$store.dispatch('fetchPitch');
    this.$store.dispatch('fetchMilestonesList');
    this.handleResize();
  },

  computed: {
    newChartData() {
      return this.chartData
    },
    yearSum() {
      const arrSum = arr => arr.reduce((a,b) => a + b, 0)
      return arrSum(this.newChartData)
    },
    marketShare() {
      return {
        chart: {
          type: 'pie',
          width: null,
          height: 200,
          marginLeft: 1,
          marginRight: 1,
          backgroundColor: this.bgColor,
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        credits: false,
        title: {
          text: null
        },
        legend: false,
        tooltip: {
          formatter: function () {
            return `${this.percentage.toFixed(2)}% <br> <b>${this.key}</b>`
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            size: '110%',
            borderColor: '#344050',
          },
        },
        series: [{
          innerSize: '65%',
          dataLabels: false,
          colors: ['#0a93cc', '#f8d054', '#11b70b', '#eda027', '#8378c7'],
          data: this.chartData
        }]
      }
    }
  },
  methods: {

    handleResize() {
      // центруем лейбл "Объем рынка"
      const marketTotal = this.$refs.marketTotal;
      this.$refs.marketTotal.style.left = `${(marketTotal.parentElement.clientWidth/2) - (marketTotal.clientWidth / 2)}px`;

      // центрируем кол-во клиентов
      const marketProspectusAmount = this.$refs.marketProspectusAmount;
      this.$refs.marketProspectusAmount.style.left = `${(marketProspectusAmount.parentElement.clientWidth / 2) - (marketProspectusAmount.offsetWidth / 2)}px`;

      // центрируем текс "клиентов" 
      const merketProspectusLabel = this.$refs.merketProspectusLabel;
      this.$refs.merketProspectusLabel.style.left = `${(merketProspectusLabel.parentElement.clientWidth / 2) - (merketProspectusLabel.offsetWidth / 2)}px`;

    }
   
  }
}
</script>

<style lang="scss" scoped>
.units-title {
  position: absolute;
  bottom: 90px;
  left: 34%;
  font-size: 25px;
  font-weight: 600;
  z-index: 0;
}

.prostects-label {
  position: absolute;
  bottom: 75px;
  // left: 5px;
  // color: rgba(0, 0, 0, 0.2);
  z-index: 0;
  font-size: 18px;
}

.market-totla-label {
  position: absolute;
  bottom: -10px;
  left: 30%;
}

.highcharts-plot-background {
	fill: #3a3939c0;
}
</style>

