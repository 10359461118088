<template>
  <div class="mt-3">
    <div class="section-half-rounded pt-1 pb-1">
      <div class="jumbotron rounded-0 section-inner left-0 bg-primary overflow-hidden col-lg-12"
        style="background-color: #2e4058 !important;">
        <img src="@/assets/img/generic/img-3.jpg" alt="Image" class="img-as-bg blend-mode--multiply">
      </div>
      <div class="container pt-4 pb-2">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <h1 class="display-4 text-white lh-110">
              {{bplan ? bplan.title : ''}}
            </h1>
            <p class="lead mb-2 text-white mt-4">
              {{pitch ? pitch.company.desc : ''}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="media mb-2 mt-4">
      <span class="fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <!-- <i class="fa-inverse fa-stack-1x text-primary fas fa-percentage"></i> -->
        <i class="fa-inverse fa-stack-1x text-primary fas fa-file-alt" data-fa-transform="shrink-2"></i>
      </span>

      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="bg-200 pr-3">
            Потенциальная возможность
          </span>
          <span class="border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="mb-0">
          Актуальная ситуация на рынке, способствует успешной реализации идеи 
        </p>
      </div>
    </div>

    <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-lg-6 pr-lg-2 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Решаемая проблема</h5>
            </div>
            <div class="card-body">
              <div v-if="pitch ? pitch.problem.selectedType == 'desc' : false">
                <p><strong class="text-800">Описание:</strong></p>
                {{pitch ? pitch.problem.desc : ''}}
              </div>
              <div v-if="pitch ? pitch.problem.selectedType == 'list' : false">
                <p><strong class="text-800">Основные характеристики:</strong></p>
                <ul class="list-group list-group-flush">
                  <li v-for="(item, index) in pitch.problem.list" :key="item.index"
                    class="list-group-item pb-0 pt-1">
                    <div v-if="item.text">
                      <small>{{index + 1}}.</small> {{item.text}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Предлагаемое решение</h5>
            </div>
            <div class="card-body">
              <div v-if="pitch ? pitch.solution.selectedType == 'desc' : false">
                <p><strong class="text-800">Описание:</strong></p>
                {{pitch ? pitch.solution.desc : ''}}
              </div>
              <div v-if="pitch ? pitch.solution.selectedType == 'list' : false">
                <p><strong class="text-800">Основные характеристики:</strong></p>
                <ul class="list-group list-group-flush">
                  <li v-for="(item, index) in pitch.solution.list" :key="item.index"
                    class="list-group-item pb-0 pt-1">
                    <div v-if="item.text">
                      <small>{{index + 1}}.</small> {{item.text}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-6 pr-lg-2 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Целевой рынок</h5>
            </div>
            <div class="card-body">
              <div v-if="pitch ? pitch.market.selectedType == 'chart' : false">          
                <div class="row">
                   <div class="col-6 px-0">
                   <div style="width: 230px;">
                      <market-chart 
                        :chartData="marketChartData"
                        :prospectus="totalMarket.prospectus" 
                        :marketSize="totalMarket.marketSize" 
                      />
                   </div>
                  </div>
                  <div class="col-6 pr-2 d-flex align-items-center">
                    <div class="fs--2">
                      <div v-for="(item, index) in chartSections" :key="item.index"
                        class="d-flex align-items-center">
                        <div class="chart-legend_point mr-3"
                          :class="`point${index+1}`">
                        </div>
                        <div class="chart-legend_title">
                          <strong class="mr-2">
                            {{item.title}}
                          </strong>
                          <small> 
                            {{marketShare(item.spendings) | numeralFormat('0.0%')}} 
                            (₽ {{ item.spendings | numeralFormat('(0a)') }})
                          </small>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
               
                <div class="row justify-content-between no-gutters">
                  <div class="col-6">
                    
                  </div>
                     
                </div>
              </div>
              <div v-if="pitch ? pitch.market.selectedType == 'list' : false">
                <p><strong class="text-800">Основные характеристики:</strong></p>
                <ul class="list-group list-group-flush">
                  <li v-for="(item, index) in pitch.market.list" :key="item.index"
                    class="list-group-item pb-0 pt-1">
                    <div v-if="item.text">
                      <small>{{index + 1}}.</small> {{item.text}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Конкурентное окружение</h5>
            </div>
            <div class="card-body p-0">
              <div class="falcon-data-table mt-3">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row mx-1">
                    <div class="col-sm-12 col-md-6"></div>
                    <div class="col-sm-12 col-md-6"></div>
                  </div>
                  <div class="table-responsive">
                    <table 
                      class="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                      <thead class="bg-200 text-900">
                        <tr role="row">
                          <th class="align-middle text-600 font-weight-light" 
                            style="min-width: 150px">
                            Конкурент
                          </th>
                          <th class="align-middle text-600 font-weight-light">
                            Наше преимещество
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="pitch">
                        <tr v-for="(item, index) in pitch.competitors.list" :key="item.index"
                          class="btn-reveal-trigger" role="row">
                          <td class="py-2 align-middle white-space-nowrap customer-name-column">
                            <a href="#">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h5 class="mb-0 fs--1">
                                    <small class="text-600">{{index + 1}}. </small>
                                    {{item.title}}
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="py-2 align-middle">
                            {{item.desc}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pitch ? pitch.investments.amount > 0 : false"
          class="col-12">
          <div class="card">
            <div class="bg-holder bg-card" 
              style="background-image:url(/img/illustrations/corner-1.png);">
            </div>
            <!--/.bg-holder-->
            <div class="card-body position-relative">
              <div class="row">
                <div class="col-8 border-right">
                  <h5 class="text-warning">Потребность в финансировании</h5>
                  <p class="fs--1 mb-0">
                    {{pitch ? pitch.investments.desc : ''}}
                  </p>
                </div>
                <div class="col-4 d-flex justify-content-center align-items-center">
                  <span class="mr-3 align-self-start">₽</span> 
                  <h2 class="font-weight-bold">
                    {{pitch.investments.amount | numeralFormat('0a')}}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>

    <div class="media mb-2 mt-4">
      <span class="fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <i class="fa-inverse fa-stack-1x text-primary fas fa-cart-plus" data-fa-transform="shrink-2"></i>
      </span>
      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="bg-200 pr-3">
            Продажи и Маркетинг
          </span>
          <span class="border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="mb-0">
          Комплекс мероприятий позволяющий дотянуться до потенциальных потребителей 
        </p>
      </div>
    </div>

    <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-6 pr-lg-2 mb-3">
          <pitch-section :pitchSectionData="pitch ? pitch.sales: {}" title="Каналы сбыта" />
        </div>
        <div class="col-6 mb-3">
          <pitch-section :pitchSectionData="pitch? pitch.marketing: {}" title="Маркетинг" />
        </div>
        <div class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Прогноз выручки</h5>
            </div>
            <div class="card-body px-0">
              <pitch-chart-revenue chartId="revenueChartAllTime" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="media mb-2 mt-3">
      <span class="fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <i class="fa-inverse fa-stack-1x text-primary fas fa-chart-bar" data-fa-transform="shrink-2"></i>
      </span>
      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="bg-200 pr-3">
            Прогноз развития
          </span>
          <span class="border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="mb-0">
          Основные прогнозные показатели и этапы проекта
        </p>
      </div>
    </div>

     <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Финансовый прогноз</h5>
            </div>
            <div class="card-body px-0">
              <pitch-chart-forecast />
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Ключевые этапы</h5>
            </div>
              <div class="card-body px-0">  
                <swiper v-if="milestones.length > 0"
                  class="pb-3"
                  :options="swiperOptions">          
                  <swiper-slide v-for="milestone in milestones" :key="milestone.index"
                  class="px-5">
                    <div class="media btn-reveal-trigger">
                      <div class="calendar">
                        <span class="calendar-month">{{ milestone.deadline | moment("MMM") }}</span>
                        <span class="calendar-day">{{ milestone.deadline | moment("D") }}</span>
                      </div>
                      <div class="media-body position-relative pl-3">
                        <h6 class="fs-0 mb-0">
                          {{ milestone.title }} 
                        </h6>
                        <p class="mt-2">
                          <span  
                            class="badge badge badge-soft-success">
                            Завершен
                            <span class="ml-1 fas fa-check" data-fa-transform="shrink-2"></span>
                          </span> 
                        </p>
                        
                      </div>
                    </div>
                    <p><small>{{ milestone.desc }} </small></p>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                </swiper>
              </div>
          </div>
        </div>
      </div>
    </section>  

    <div class="media mb-2 mt-3">
      <span class="fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <i class="fa-inverse fa-stack-1x text-primary fas fa-user-friends" data-fa-transform="shrink-4"></i>      
      </span>
      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="bg-200 pr-3">
            Комманда и Ресурсы
          </span>
          <span class="border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="mb-0">
          Наша комманда и ресурсы, которые позволят достичь запланированные показатели
        </p>
      </div>
    </div>

    <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-6 pr-lg-2 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Ключевые участники</h5>
            </div>
            <div class="card-body p-0">
              <div class="falcon-data-table mt-3">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row mx-1">
                    <div class="col-sm-12 col-md-6"></div>
                    <div class="col-sm-12 col-md-6"></div>
                  </div>
                  <div class="table-responsive">
                    <table 
                      class="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                      <tbody v-if="pitch">
                        <tr v-for="(item) in pitch.team.list" :key="item.index"
                          class="btn-reveal-trigger" role="row">
                          <td class="py-2 align-middle white-space-nowrap customer-name-column">
                            <a href="#">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h5 class="mb-0 fs--1">
                                    <div class="avatar avatar-2xl">
                                      <img :src="item.avatar != null ? item.avatar : `@/assets/img/team/2.jpg`" alt="">
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="py-2 align-middle white-space-nowrap customer-name-column px-2">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <span class="fs-0 text-1000">
                                    {{item.fio}}
                                  </span>
                                  <h6 class="mt-1 text-800">
                                    {{item.position}}
                                  </h6>
                                </div>
                              </div>
                          </td>
                          <td class="py-2 align-middle text-500">
                            {{item.desc}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Ключевые ресурсы</h5>
            </div>
            <div class="card-body p-0">
              <div class="falcon-data-table mt-3">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row mx-1">
                    <div class="col-sm-12 col-md-6"></div>
                    <div class="col-sm-12 col-md-6"></div>
                  </div>
                  <div class="table-responsive">
                    <table 
                      class="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                      <tbody v-if="pitch">
                        <tr v-for="(item) in pitch.resources.list" :key="item.index"
                          class="btn-reveal-trigger" role="row">
                          <td class="py-2 align-middle white-space-nowrap customer-name-column">
                            <a href="#">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h5 class="mb-0 fs--1">
                                    <div class="avatar resource_image">
                                      <img :src="item.image != null ? item.image : `@/assets/img/team/2.jpg`" alt="">
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="py-2 align-middle white-space-nowrap customer-name-column px-2">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h6 class="fs-0">
                                    {{item.title}}
                                  </h6>
                                </div>
                              </div>
                          </td>
                          <td class="py-2 align-middle text-500">
                            {{item.desc}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </div>
</template>

<script>
import moment from 'moment'

import PitchSection from '@/components/app/widgets/Pitch/PitchPreviewSegment'
import MarketChart from '@/components/app/charts/MarketDonut';
import PitchChartRevenue from '@/components/app/charts/PitchChartRevenueRenderer';
import PitchChartForecast from '@/components/app/charts/PitchChartRenderer';


export default {
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    }
  }),
  
  components: {
    MarketChart,
    PitchSection,
    PitchChartRevenue,
    PitchChartForecast,
  },

  mounted() {
    this.$store.dispatch('fetchPitch');
    this.$store.dispatch('fetchMilestonesList');
  },

  computed: {
    bplan() {
      return this.$store.getters.getActiveBPlan;
    },
    
    pitch() {
      return this.$store.getters.getPitch;
    },

    milestones() {
      var list = this.$store.getters.getMilestonesList;
      console.log(list)
      var sortList = list.sort(function(a, b) {
        return moment(a.deadline) - moment(b.deadline); 
      });
      console.log(sortList)
      return sortList
    },

    chartSections: {
      get: function() {
        const pitch = this.pitch;
        if (pitch) {
          return pitch.market.chart.sort(function(a, b) {
            return b.spendings - a.spendings; // сортируем по убыванию
          });
        } else {
          return [];
        }
      },
      set: function(newValue) {
        this.$store.commit('setPitchMarketChartSegment', newValue);
      }
    },

    totalMarket() {
      var totalMarket = {prospectus: 0, marketSize: 0}
      this.chartSections.map(item => {
        totalMarket.prospectus += item.prospectus;
        totalMarket.marketSize += (item.spendings * item.prospectus);
      });

      return totalMarket
    },

    marketChartData() {
      var chartData = []
      chartData = this.chartSections.map(item => {
        return {
          name: item.title, 
          y: item.spendings
        }
      });

      return chartData
    }
  },

  methods: {
    marketShare(amount) {
      return (amount / this.totalMarket.marketSize)
    },

    getInfo: function(event) {
      console.log(event);
      this.$refs.tinySlider.slider.getInfo();
    }
  }

}
</script>

<style lang="scss" scoped>

.swiper-pagination {
  bottom: 0px;
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-white {
  color: #5e6e82;
}

.resource_image {
  height: 40px;
  width: 60px;
}


.section-half-rounded {
  padding-top: 7rem;
  padding-bottom: 7rem;
  background: transparent;
  position: relative; 
}

.section-half-rounded .section-inner {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -2; 
}

@media (max-width: 991.98px) {
  .section-half-rounded .section-inner {
    width: 100%; 
  } 
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #FFF;
  border-radius: 0.5rem; 
  min-height: 200px;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
 
  } 
}

.jumbotron {
  position: relative; 
}
  
.jumbotron:not([class*="rounded-"]) {
  border-radius: 2rem; 
}

.jumbotron:not([class*="rounded-"]) > img {
  border-radius: 2rem; 
}

.jumbotron.rounded-0 {
  border-radius: 0 !important; 
}
  
.jumbotron.rounded {
  border-radius: 2rem !important; 
}

.jumbotron.rounded-right {
  border-radius: 0 2rem 2rem 0 !important; 
}

.jumbotron.rounded-left {
  border-radius: 5rem 0 0 5rem !important; 
}
  
.jumbotron.rounded-top-right {
  border-radius: 0 5rem 0 0 !important; 
}

.jumbotron.rounded-top-left {
  border-radius: 5rem 0 0 0 !important; 
}

.jumbotron.rounded-bottom-right {
  border-radius: 0 0 5rem 0 !important; 
}

.jumbotron.rounded-bottom-left {
  border-radius: 0 0 0 5rem !important; 
}

.jumbotron.rounded-diagonal-left {
  border-radius: 5rem 0 5rem 0 !important; 
}
  
.jumbotron.rounded-diagonal-right {
  border-radius: 0 5rem 0 5rem !important; 
}

// .rounded-right {
//   border-top-right-radius: 0.25rem !important;
//   border-bottom-right-radius: 0.25rem !important; }

.img-as-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover; 
  -o-object-fit: cover;
}

.blend-mode--multiply {
  mix-blend-mode: multiply; 
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blend-mode--multiply:after {
    content: '';
    background: rgba(0, 0, 214, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%; 
  } 
}

.zindex-100 {
  z-index: 100 !important; 
}

.translate-y-n10, .hover-translate-y-n10:hover {
  transform: translateY(-10px) !important; 
}

.chart-legend_point {
  display: -moz-inline-box;
  width: 10px;
  height: 10px !important;
  border-radius: 2px;
  &.point1 {
    background: #0a93cc;
  }
  &.point2 {
    background: #f8d054;
  }
  &.point3 {
    background: #11b70b;
  }
  &.point4 {
    background: #eda027;
  }
  &.point5 {
    background: #8378c7;
  }
}

.chart-legend_point {
  display: -moz-inline-box;
  height: 30px;
}

</style>>

