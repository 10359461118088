<template>
  <div style="position: relative; height: 300px; overflow: hidden;">
    <b-overlay 
      :show="loading"
      spinner-variant="primary"
      spinner-small 
      rounded="sm">
      <div class="d-flex align-content-between">
        <div style="width: 35%">
          <highcharts :options="revenueChartOptions"></highcharts>
        </div>
        <div style="width: 35%">
          <highcharts :options="expnsChartOptions"></highcharts>
        </div>
        <div style="width: 30%">
          <highcharts :options="netProfitChartOptions"></highcharts>
        </div>
        
      </div>
    </b-overlay>
    
  </div>
</template>

<script>

export default {
  props: ['chartId'],
  data: () => ({
    chartOptions: { 
      chart: {
        type: 'column',
        width: 300,
        height: 350,
        backgroundColor: null
      },
      legend: false,
      credits: false,
      title: {
          text: null
      },
      xAxis: {
        categories: []
      }
    },
    revenueChartOptions: null,
    expnsChartOptions: null,
    netProfitChartOptions: null,
    loading: false,
  }),
  async beforeMount() {
    this.loading = true;
    this.revenueChartOptions = this.chartOptions;
    this.expnsChartOptions = this.chartOptions;
    this.netProfitChartOptions = this.chartOptions;
    this.revenueChartOptions = await this.$store.dispatch(`revenueChartByYearData`, {width: 300, height: 300});
    this.expnsChartOptions = await this.$store.dispatch(`expnsChartByYearData`, {width: 300, height: 300});
    this.netProfitChartOptions = await this.$store.dispatch(`netProfitByYearData`, {width: 300, height: 300});
    
    this.loading = false;
  },
}
</script>

<style lang="scss" scoped>

</style>

