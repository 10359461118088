<template>
  <div class="card h-100">
    <div class="card-header bg-light d-flex flex-between-center py-2">
      <h5 class="mb-0 text-500 font-weight-light">{{ title }}</h5>
    </div>
    <div class="card-body px-2 px-md-3">
      <div v-if="pitchSectionData.selectedType == 'desc'">
        <p><strong class="text-800">Описание:</strong></p>
        <p>{{ pitchSectionData.desc }}</p>
      </div>
      <div v-if="pitchSectionData.selectedType == 'list'">
        <p><strong class="text-800">Основные характеристики:</strong></p>
        <ul class="list-group list-group-flush">
          <li v-for="(item, index) in pitchSectionData.list" :key="item.index"
            class="list-group-item pb-0 pt-2 border-0"
            :class="{'d-none': item.text == ''}">
            <div v-if="item.text">
              <small>{{index + 1}}.</small> {{item.text}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pitchSectionData: {
      type: Object,
      defauld: {}
    },
    title: {
      tyep: String,
      defauld: ''
    }
  }
}
</script>

<style>

</style>